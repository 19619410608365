import { FC, ReactNode } from "react";
import { NextSeo } from "next-seo";
import { Toaster } from "react-hot-toast";

import { seo } from "lib/seo";
import { AuthProvider } from "lib/auth";

import { DefaultGlobalContextI, GlobalContextProvider } from "@/context/global";
import PrimaryNavigationV3 from "@/components/primary-navigation/v3";
import { MetaI } from "@/interfaces/meta";

import { CookieMessageNotifier } from "@/helpers/cookies";

import { RedirectsProvider } from "lib/auth/redirects-provider";
import { IntercomComponent } from "@/helpers/intercom";

import CustomMeetingsToaster from "@/components/shared/custom-meetings-toaster";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/api/query-client";
import { WidgetsProvider } from "@/components/shared/widgets";

export interface HomepageLayoutPropsI {
  meta?: MetaI;
  navTitle?: any;
  navHomepageUrl?: string;
  children?: any;
  globalContext?: DefaultGlobalContextI;
  isBackgroundVisible?: boolean;
  contentContainerClassName?: string;
  pageContainerClassName?: string;
  headerClassName?: string;
  isCurrentTimeVisible?: boolean;
  hideNavbar?: boolean;
}

const HomepageLayout: FC<HomepageLayoutPropsI> = ({
  meta = {},
  navTitle,
  children,
  globalContext,
  isBackgroundVisible,
  contentContainerClassName,
  pageContainerClassName,
  headerClassName,
  isCurrentTimeVisible = true,
  hideNavbar = false,
}): ReactNode => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <GlobalContextProvider
        {...((globalContext as DefaultGlobalContextI) || {})}
      >
        <RedirectsProvider>
          <NextSeo {...seo(meta)} />
          <IntercomComponent />
          <WidgetsProvider>
            <main data-theme="glencoco">
              <Toaster
                containerStyle={{
                  top: 80,
                }}
                position="top-center"
              />

              <CustomMeetingsToaster />

              <PrimaryNavigationV3
                title={navTitle}
                isCurrentTimeVisible={isCurrentTimeVisible}
                isBackgroundVisible={isBackgroundVisible}
                contentContainerClassName={contentContainerClassName}
                pageContainerClassName={pageContainerClassName}
                headerClassName={headerClassName}
                hideNavbar={hideNavbar}
              >
                {children}
              </PrimaryNavigationV3>

              <CookieMessageNotifier />
            </main>
          </WidgetsProvider>
        </RedirectsProvider>
      </GlobalContextProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default HomepageLayout;
